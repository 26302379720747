<template>
    <b-container class="c-page">
        <h1 class="mb-3 mt-2">{{seo.name}}</h1>
        <div class="v-content" v-html="content"></div>
    </b-container>
</template>

<script>
export default {
    name: "page",
    data() {
        return {
            id: this.$route.params.id,
            lang: this.$route.params.lang,
            content: '',
            seo: ''
        }
    },
    methods: {
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/page-content/page-item.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {
                        'id': this.id,
                        'lang': this.lang
                    },
                    method: 'GET'
                });
    
                this.content = response.data.content;
                this.seo = response.data.seo;
    
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', 'Žádná data');
            }
        },
    },
    watch: {
        // whenever question changes, this function will run
        '$store.state.local': function () {
          if (this.lang != this.$store.state.local) {
            this.lang = this.$store.state.local;
            this.$router.push('/' + this.$store.state.local + '/page/' + this.id);
            this.loadData();
          }
        }
    },
    created () {
        this.loadData();
    }
    
}
</script>

<style scoped>

</style>
